import React from "react";
import Home_Banner from "./Home_Banner/Home_Banner";
import Category from "./Category/Category";
import TrendingProducts from "./TrendingProducts/TrendingProducts";
import Sale from "./Sale/Sale";
import Motivation from "./Motivation/Motivation";


function HomePage() {
  return (
    <>
      <Home_Banner />
      <Category />
      <TrendingProducts />
      <Sale />
      <Motivation />
    </>
  );
}

export default HomePage;
