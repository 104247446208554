import React, { useRef, useState, useEffect, useContext } from "react";
import "./Sale.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import { Col, Container, Row } from "react-bootstrap";
import { Context } from "../../../utils/context";
import { saleBanner } from "../../../utils/apis/common/Common";

function Sale() {

  const [getSaleBanner, setSaleBanner] = useState();
  const { getData, IMG_URL, sellerId, sellerDomain } = useContext(Context);
  const getProject = async (data) => {
    const res = await saleBanner(sellerDomain);
    if (res?.success) {
      setSaleBanner(res?.data);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  useEffect(() => {
    getProject();
  }, [sellerDomain]);
  // const SalesCards = [
  //   {
  //     imagesone: process.env.PUBLIC_URL + "/assets/Homepage/Sale/canon.png",
  //     imagestwo: process.env.PUBLIC_URL + "/assets/Homepage/Sale/Sale.png",
  //   },
  //   {
  //     imagesone: process.env.PUBLIC_URL + "/assets/Homepage/Sale/canon.png",
  //     imagestwo: process.env.PUBLIC_URL + "/assets/Homepage/Sale/Sale.png",
  //   },
  //   {
  //     imagesone: process.env.PUBLIC_URL + "/assets/Homepage/Sale/canon.png",
  //     imagestwo: process.env.PUBLIC_URL + "/assets/Homepage/Sale/Sale.png",
  //   },
  // ];
  return (
    <>
      <section className="Sale">
        <Container>
          <Swiper
            className="mySwiper"
            spaceBetween={30}
          >
            {getSaleBanner?.map((item, index) => (
              <SwiperSlide key={index}>
                <Row>
                  <Col xxl={4} xl={4} lg={4} md={4}>
                    <div className="Sale-main">
                      <img
                        src={IMG_URL + item?.image1}
                        className="sale-img-one"
                      />
                    </div>
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={8}>
                    <div className="Sale-main">
                      <img
                        src={IMG_URL + item?.image2}
                        className="sale-img-two"
                      />
                    </div>
                  </Col>
                </Row>
              </SwiperSlide>
            ))}
          </Swiper>

        </Container>
      </section>
    </>
  );
}
export default Sale;

